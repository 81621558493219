import React from "react";
import { Col, Form, Input, InputNumber, Row } from "@pankod/refine-antd";
import SelectJuz from "../../../components/select-juz/select-juz";
import SelectSurah from "../../../components/select-surah/select-surah";
import ArabInput from "../../../components/arab-input/arab-input";

interface props {
  formProps: any;
}

export const AyatForm: React.FC<props> = ({ formProps }) => {
  return (
    <Form {...formProps} layout="vertical">
      <Row gutter={[32, 32]}>
        <Col>
          <Form.Item
            label="Порядковый номер"
            name={["number"]}
            rules={[{ required: true }]}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col flex={1}>
          <SelectJuz />
        </Col>
        <Col flex={1}>
          <SelectSurah />
        </Col>
      </Row>

      <Form.Item hidden label="Id" name={["id"]}>
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item label="Аят" name={["arabText"]} rules={[{ required: true }]}>
        <ArabInput />
      </Form.Item>
      <Form.Item
        label="Перевод кириллица"
        name={["text"]}
        rules={[{ required: true }]}
      >
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item
        label="Перевод латиница"
        name={["latinText"]}
        rules={[{ required: true }]}
      >
        <Input.TextArea rows={10} />
      </Form.Item>
    </Form>
  );
};
