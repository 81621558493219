import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, useForm } from "@pankod/refine-antd";
import { SurahForm } from "./components/surah-form";

const EditSurah: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <SurahForm formProps={formProps} />
    </Edit>
  );
};

export default EditSurah;
