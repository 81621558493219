import React from "react";
import { useShow } from "@pankod/refine-core";
import { NumberField, Show, TextField, Typography } from "@pankod/refine-antd";
import { IFeedback } from "../../models/Feedback";

const { Title } = Typography;

interface props {}

const FeedbackDetails: React.FC<props> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data as IFeedback;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>ID</Title>
      <NumberField value={record?.id || ""} />
      <Title level={5}>Имя</Title>
      <TextField value={record?.name} />
      <Title level={5}>Email</Title>
      <TextField value={record?.email} />
      <Title level={5}>Текст обращения</Title>
      <TextField value={record?.message} />
      <Title level={5}>Дата обращения</Title>
      <TextField value={record?.created_at} />
    </Show>
  );
};

export default FeedbackDetails;
