import React from "react";
import { Form, Select, useSimpleList } from "@pankod/refine-antd";
import { IJuz } from "../../models/Juz";

interface props {}

const SelectJuz: React.FC<props> = () => {
  const { listProps } = useSimpleList<IJuz>({
    resource: "admin/juzs",
  });

  return (
    <Form.Item
      label={"Выберите раздел"}
      name={["juz_id"]}
      rules={[{ required: true }]}
    >
      <Select
        showSearch
        filterOption={(input: string, option: any) =>
          option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
        }
        placeholder={"Выберите раздел"}
        options={listProps?.dataSource?.map((juz) => ({
          label: `${juz.arabTitle} | ${juz.title}`,
          value: juz.id,
        }))}
      />
    </Form.Item>
  );
};

export default SelectJuz;
