import React from "react";
import { BaseRecord, IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  MarkdownField,
  ShowButton,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { IAyat } from "../../models/Ayat";
import { PaginationResponse } from "../../data-types";

const AyatList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });
  const data = tableProps.dataSource as unknown as PaginationResponse<IAyat>;

  return (
    <List>
      <Table
        {...tableProps}
        pagination={{
          current: data?.current_page,
          total: data?.total,
          pageSize: data?.per_page,
        }}
        dataSource={data?.data}
        rowKey="id"
      >
        <Table.Column dataIndex="number" title="Порядковый номер" />
        <Table.Column dataIndex="arabText" title="Аят" align={"right"} />
        <Table.Column
          dataIndex="text"
          title="Перевод кириллица"
          render={(value: string) =>
            value && <MarkdownField value={value?.slice(0, 25) + "..."} />
          }
        />
        <Table.Column
          dataIndex="latinText"
          title="Перевод латиница"
          render={(value: string) =>
            value && <MarkdownField value={value?.slice(0, 25) + "..."} />
          }
        />
        <Table.Column
          title={"Раздел"}
          key={"juz_id"}
          render={(entity: IAyat) =>
            `${entity.juz.sort}. ${entity.juz.arabTitle} (${entity.juz.title})`
          }
        />
        <Table.Column
          title={"Сура"}
          key={"surah_id"}
          render={(entity: IAyat) =>
            `${entity.surah.sort}. ${entity.surah.arabTitle} (${entity.surah.title})`
          }
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          align={"right"}
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size={"small"} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default AyatList;
