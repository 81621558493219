import React from "react";
import { BaseRecord, IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  MarkdownField,
  ShowButton,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { ISurah } from "../../models/Surah";

const SurahList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<ISurah>({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="sort" title="Номер" />
        <Table.Column
          dataIndex="arabTitle"
          title="Название Суры"
          align={"right"}
        />
        <Table.Column dataIndex="title" title="Перевод кириллица" />
        <Table.Column dataIndex="latTitle" title="Перевод латиница" />
        <Table.Column
          dataIndex="description"
          title="Описание"
          render={(value: string) =>
            value && <MarkdownField value={value?.slice(0, 80) + "..."} />
          }
        />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          align={"right"}
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size={"small"} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default SurahList;
