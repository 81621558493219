import React from "react";

import { Refine } from "@pankod/refine-core";
import {
  ErrorComponent,
  Layout,
  notificationProvider,
  ReadyPage,
} from "@pankod/refine-antd";
import "@pankod/refine-antd/dist/reset.css";

import routerProvider from "@pankod/refine-react-router-v6";
import { CreateSurah, EditSurah, SurahDetails, SurahList } from "./pages/surah";
import { Login } from "./pages/login/login";
import { httpClient } from "./services/httpClient";
import { authProvider } from "./services/authProvider";
import { CreateJuz, EditJuz, JuzDetails, JuzList } from "./pages/juz";
import { AyatDetails, AyatList, CreateAyat, EditAyat } from "./pages/ayat";
import { dataProvider } from "services/dataProvider";
import { FeedbackDetails, FeedbackList } from "./pages/feedback";

function App() {
  return (
    <Refine
      authProvider={authProvider}
      dataProvider={dataProvider(
        process.env.REACT_APP_API_URL as string,
        httpClient
      )}
      notificationProvider={notificationProvider}
      Layout={Layout}
      ReadyPage={ReadyPage}
      catchAll={<ErrorComponent />}
      routerProvider={routerProvider}
      LoginPage={() => <Login />}
      resources={[
        {
          options: {
            label: "Разделы",
          },
          canDelete: true,
          name: "admin/juzs",
          list: JuzList,
          show: JuzDetails,
          edit: EditJuz,
          create: CreateJuz,
          icon: "",
        },
        {
          options: {
            label: "Суры",
          },
          canDelete: true,
          name: "admin/surahs",
          list: SurahList,
          show: SurahDetails,
          edit: EditSurah,
          create: CreateSurah,
          icon: "",
        },
        {
          options: {
            label: "Аяты",
          },
          canDelete: true,
          name: "admin/ayats",
          list: AyatList,
          show: AyatDetails,
          edit: EditAyat,
          create: CreateAyat,
          icon: "",
        },
        {
          options: {
            label: "Обратная связь",
          },
          name: "admin/feedbacks",
          list: FeedbackList,
          show: FeedbackDetails,
          icon: "",
        },
      ]}
    />
  );
}

export default App;
