import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Create, useForm } from "@pankod/refine-antd";
import { SurahForm } from "./components/surah-form";

const CreateSurah: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <SurahForm formProps={formProps} />
    </Create>
  );
};

export default CreateSurah;
