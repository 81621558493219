import React from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { NumberField, Show, TextField, Typography } from "@pankod/refine-antd";
import { IJuz } from "../../models/Juz";
import ArabTitle from "../../components/arab-title/arab-title";

const { Title } = Typography;

const JuzDetails: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data as IJuz;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Порядковый номер</Title>
      <NumberField value={record?.sort || ""} />
      <Title level={5}>Название раздела</Title>
      <ArabTitle title={record?.arabTitle} />
      <Title level={5}>Перевод кириллица</Title>
      <TextField value={record?.title} />
      <Title level={5}>Перевод латиница</Title>
      <TextField value={record?.latTitle} />
    </Show>
  );
};

export default JuzDetails;
