import React from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import { NumberField, Show, TextField, Typography } from "@pankod/refine-antd";
import { IAyat } from "../../models/Ayat";
import ArabTitle from "../../components/arab-title/arab-title";

const { Title } = Typography;

const AyatDetails: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data as IAyat;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Порядковый номер</Title>
      <NumberField value={record?.number || ""} />
      <Title level={5}>Текст аята</Title>
      <ArabTitle title={record?.arabText} />
      <Title level={5}>Перевод кириллица</Title>
      <TextField value={record?.text} />
      <Title level={5}>Перевод латиница</Title>
      <TextField value={record?.latinText} />
      <Title level={5}>Раздел</Title>
      <TextField
        value={`${record?.juz.sort}. ${record?.juz.arabTitle} (${record.juz.title})`}
      />
      <Title level={5}>Сура</Title>
      <TextField
        value={`${record?.surah.sort}. ${record?.surah.arabTitle} (${record.surah.title})`}
      />
    </Show>
  );
};

export default AyatDetails;
