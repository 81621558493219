import { AuthProvider } from "@pankod/refine-core";
import { httpClient } from "./httpClient";
import { AxiosResponse } from "axios";
import { AuthResponse } from "../data-types/auth";

export const authProvider: AuthProvider = {
  login: ({ email, password, remember_token }) => {
    return httpClient
      .post("/login", { email, password, remember_token })
      .then((response: AxiosResponse<AuthResponse>) => {
        localStorage.setItem("auth", response.data.access_token);
      });
  },
  logout: () => {
    localStorage.removeItem("auth");
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem("auth") ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    if (error.status === 401) {
      return Promise.reject("/login");
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};
