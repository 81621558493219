import React from "react";
import { Typography } from "@pankod/refine-antd";

interface props {
  title: string | undefined;
}

const ArabTitle: React.FC<props> = ({ title }) => {
  return (
    <Typography.Paragraph style={{ textAlign: "right" }}>
      {title}
    </Typography.Paragraph>
  );
};

export default ArabTitle;
