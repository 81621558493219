import React from "react";
import {
  List,
  MarkdownField,
  ShowButton,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { PaginationResponse } from "../../data-types";
import { IFeedback } from "../../models/Feedback";
import { BaseRecord } from "@pankod/refine-core";

interface props {}

const FeedbackList: React.FC<props> = () => {
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const data =
    tableProps.dataSource as unknown as PaginationResponse<IFeedback>;

  return (
    <List>
      <Table
        {...tableProps}
        pagination={{
          current: data?.current_page,
          total: data?.total,
          pageSize: data?.per_page,
        }}
        rowKey={"id"}
        dataSource={data?.data}
      >
        <Table.Column title={"ID"} dataIndex={"id"} />
        <Table.Column title={"Имя"} dataIndex={"name"} />
        <Table.Column title={"Email"} dataIndex={"email"} />
        <Table.Column
          dataIndex="message"
          title="Текст обращения"
          render={(value: string) =>
            value && <MarkdownField value={value?.slice(0, 80) + "..."} />
          }
        />
        <Table.Column title={"Дата обращения"} dataIndex={"created_at"} />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          align={"right"}
          render={(_, record: BaseRecord) => (
            <ShowButton hideText size="small" recordItemId={record.id} />
          )}
        />
      </Table>
    </List>
  );
};

export default FeedbackList;
