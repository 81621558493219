import React from "react";
import { Form, Input, InputNumber } from "@pankod/refine-antd";
import ArabInput from "../../../components/arab-input/arab-input";

interface props {
  formProps: any;
}

export const SurahForm: React.FC<props> = ({ formProps }) => {
  return (
    <Form {...formProps} layout="vertical">
      <Form.Item
        label="Порядковый номер"
        name={["sort"]}
        rules={[{ required: true }]}
      >
        <InputNumber />
      </Form.Item>
      <Form.Item hidden label="Id" name={["id"]}>
        <Input readOnly disabled />
      </Form.Item>
      <Form.Item
        label="Название суры"
        name={["arabTitle"]}
        rules={[{ required: true }]}
      >
        <ArabInput />
      </Form.Item>
      <Form.Item
        label="Перевод кириллица"
        name={["title"]}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Перевод латиница"
        name={["latTitle"]}
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Описание кириллица (необязательно)" name="description">
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item
        label="Описание латиница (необязательно)"
        name="latDescription"
      >
        <Input.TextArea rows={10} />
      </Form.Item>
    </Form>
  );
};
