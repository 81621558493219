import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Edit, useForm } from "@pankod/refine-antd";
import { AyatForm } from "./components/ayat-form";

const EditAyat: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <AyatForm formProps={formProps} />
    </Edit>
  );
};

export default EditAyat;
