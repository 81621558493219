import React from "react";
import { BaseRecord, IResourceComponentsProps } from "@pankod/refine-core";
import {
  DeleteButton,
  EditButton,
  List,
  ShowButton,
  Space,
  Table,
  useTable,
} from "@pankod/refine-antd";
import { IJuz } from "../../models/Juz";

const JuzList: React.FC<IResourceComponentsProps> = () => {
  const { tableProps } = useTable<IJuz>({
    syncWithLocation: true,
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
        <Table.Column dataIndex="sort" title="Номер" />
        <Table.Column
          dataIndex="arabTitle"
          title="Название Раздела"
          align={"right"}
        />
        <Table.Column dataIndex="title" title="Перевод кириллица" />
        <Table.Column dataIndex="latTitle" title="Перевод латиница" />
        <Table.Column
          title="Actions"
          dataIndex="actions"
          align={"right"}
          render={(_, record: BaseRecord) => (
            <Space>
              <ShowButton hideText size="small" recordItemId={record.id} />
              <EditButton hideText size="small" recordItemId={record.id} />
              <DeleteButton hideText size={"small"} recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};

export default JuzList;
