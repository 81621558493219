import React from "react";
import { Form, Select, useSimpleList } from "@pankod/refine-antd";
import { ISurah } from "../../models/Surah";

interface props {}

const SelectSurah: React.FC<props> = () => {
  const { listProps } = useSimpleList<ISurah>({
    resource: "admin/surahs",
  });

  return (
    <Form.Item
      label={"Выберите суру"}
      name={["surah_id"]}
      rules={[{ required: true }]}
    >
      <Select
        placeholder={"Выберите суру"}
        showSearch
        filterOption={(input: string, option: any) => {
          return (
            option?.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
          );
        }}
        options={listProps?.dataSource?.map((surah) => ({
          label: `${surah.arabTitle} | ${surah.title}`,
          value: surah.id,
        }))}
      />
    </Form.Item>
  );
};

export default SelectSurah;
