import axios, { AxiosRequestConfig } from "axios";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

httpClient.interceptors.request.use((request: AxiosRequestConfig) => {
  const token = localStorage.getItem("auth");
  if (request.headers) {
    request.headers["Authorization"] = `Bearer ${token}`;
  } else {
    request.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return request;
});
