import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Create, useForm } from "@pankod/refine-antd";
import { AyatForm } from "./components/ayat-form";

const CreateAyat: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <AyatForm formProps={formProps} />
    </Create>
  );
};

export default CreateAyat;
