import React from "react";
import { IResourceComponentsProps } from "@pankod/refine-core";
import { Create, useForm } from "@pankod/refine-antd";
import { JuzForm } from "./components/juz-form";

const CreateJuz: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps } = useForm();

  return (
    <Create saveButtonProps={saveButtonProps}>
      <JuzForm formProps={formProps} />
    </Create>
  );
};

export default CreateJuz;
