import React from "react";
import { IResourceComponentsProps, useShow } from "@pankod/refine-core";
import {
  MarkdownField,
  NumberField,
  Show,
  TextField,
  Typography,
} from "@pankod/refine-antd";
import { ISurah } from "../../models/Surah";
import ArabTitle from "../../components/arab-title/arab-title";

const { Title } = Typography;

const SurahDetails: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { data, isLoading } = queryResult;

  const record = data?.data as ISurah;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>Порядковый номер</Title>
      <NumberField value={record?.sort || ""} />
      <Title level={5}>Название суры</Title>
      <ArabTitle title={record?.arabTitle} />
      <Title level={5}>Перевод кириллица</Title>
      <TextField value={record?.title} />
      <Title level={5}>Перевод латиница</Title>
      <TextField value={record?.latTitle} />
      <Title level={5}>Описание кириллица</Title>
      <MarkdownField value={record?.description} />
      <Title level={5}>Описание латиница</Title>
      <MarkdownField value={record?.latDescription} />
    </Show>
  );
};

export default SurahDetails;
